import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { pascalCase } from "../../api/strings";
import variants from "../../theme/variants";

const CustomConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	active: {
		"& $line": {
			borderColor: variants[0].palette.secondary.main,
		},
	},
	completed: {
		"& $line": {
			borderColor: variants[0].palette.secondary.main,
		},
	},
	line: {
		borderColor: "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
	root: {
		color: "#eaeaf0",
		display: "flex",
		height: 22,
		alignItems: "center",
	},
	active: {
		color: variants[0].palette.secondary.main,
	},
	circle: {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
	completed: {
		color: variants[0].palette.secondary.main,
		zIndex: 1,
		fontSize: 18,
	},
});

function QontoStepIcon({ active, completed }) {
	const classes = useQontoStepIconStyles();

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: completed,
			})}
		>
			{active && !completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	);
}

QontoStepIcon.propTypes = {
	active: PropTypes.bool, // Whether this step is active.
	completed: PropTypes.bool, // Mark the step as completed. Is passed to child components.
};

export default function CustomizedSteppers({
	activeStep,
	steps,
	// children,
}) {
	const { t } = useTranslation();

	return (
		<Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
			{Array.isArray(steps) &&
				steps.map((label) => (
					<Step key={label}>
						<StepLabel StepIconComponent={QontoStepIcon}>{t(`translation:commercialAgreementCaStatus${pascalCase(label)}`)}</StepLabel>
					</Step>
				))}
		</Stepper>
	);
}
