import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { desaturate, lighten } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetcher } from "../../api/fetcher";
import variants from "../../theme/variants";
import { LoadingButton } from "./Buttons";
import { useCustomSnackbar } from "./Snackbar";

// #region Customisation des lignes de porté dans la séléction
const useStyles = makeStyles((theme) => ({
	contractorLine: {
		padding: theme.spacing(2),
		cursor: "pointer",
		// transition: "background-color .1s ease-out",
		"&:hover": {
			backgroundColor: lighten(0.1, desaturate(0.3, theme.palette.primary.main)),
			color: theme.palette.primary.contrastText,
		},
	},
	selectedContractorLine: {
		borderLeft: "2px solid black",
		fontWeight: "bold",
	},
}));

const CustomerLine = ({ selected, setSelected, elm }) => {
	const classes = useStyles();

	return (
		<Typography
			className={classes.contractorLine + " " + (selected?.No === elm?.No ? classes.selectedContractorLine : "")}
			onClick={() => setSelected(elm)}
		>
			<b>{elm.No}</b> {` - ${elm.Name} ${elm.CustomerType}`}
		</Typography>
	);
};
// #endregion Customisation des lignes de porté dans la séléction

/**
 * Permet de rechercher un customer en fonction du porté
 * @param {boolean} open
 * @param {function} setOpen
 * @param {function} cb
 * @param {string} contractorNo
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomerSearch({ open, setOpen, cb, contractorNo }) {
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [customers, setCustomers] = useState(); // Liste complète des clients
	const [selected, setSelected] = useState(); // Le client sélectionné
	const [search, setSearch] = useState(""); // Le contenu du champ de recherche

	const [fetchLoading, setFetchLoading] = useState(false);
	const fetchCustomers = () => {
		setFetchLoading(true);
		fetcher(signal, "GET", `/customer/by-contractor/${contractorNo}`)
			.then((data) => {
				if (!data) return;
				if (data === 204) setCustomers(null);
				else if (data?.Customers) setCustomers(data.Customers);
			})
			.catch((err) => {
				console.error(err);
				snackbar.showError(t("translation:noCustomerFound"));
			})
			.finally(() => setFetchLoading(false));
	};

	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		// Tant que l'API ne renvoie rien, on relance la requête à chaque ouverture
		if (open && !customers) {
			fetchCustomers();
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	// On refait un fetch quand le numéro de porté change
	useEffect(() => {
		if (contractorNo) fetchCustomers();
	}, [contractorNo]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reset de la recherche quand la liste des portés change
	useEffect(() => setSearch(""), [customers]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);
	}

	function handleClose() {
		setOpen(false);
		setSelected(undefined); // On réinitialise le choix du client à la fermeture de l'assistant
	}

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				{t("translation:customerSearch")}
			</DialogTitle>

			<DialogContent style={{ overflowY: "visible" }} dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("translation:dialogTypeToSearch")}
					autoFocus
				/>
			</DialogContent>

			<DialogContent dividers>
				<Paper style={{ overflow: "auto" }}>
					{customers === undefined && (
						<>
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
						</>
					)}
					{customers === null && (
						<Grid container>
							<Grid item xs={12} sm={9}>
								<Alert severity="info" variant="filled">
									{t("translation:noContractorsFound")}
								</Alert>
							</Grid>
							<Grid container item sm justify="flex-end">
								<LoadingButton color="secondary" variant="contained" loading={fetchLoading} onClick={() => fetchCustomers()} fullWidth>
									{t("translation:retry")}
								</LoadingButton>
							</Grid>
						</Grid>
					)}

					{Array.isArray(customers) &&
						customers
							?.filter((contractor) => {
								// On ne filtre que ces propriétées
								const { No, Name, CustomerType } = contractor;

								return `${No} ${Name} ${CustomerType}`.toLowerCase().includes(search?.trim()?.toLowerCase());
							})

							.map((elm) => <CustomerLine key={elm.No} selected={selected} setSelected={setSelected} elm={elm} />)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setOpen(false)}>{t("translation:cancel")}</Button>
				<Button
					variant="contained"
					onClick={() => {
						// On appelle le callback avec le porté sélectionné
						typeof cb === "function" && cb(selected);
						handleClose();
					}}
					disabled={selected === undefined}
					color="primary"
				>
					{t("translation:select")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
