import { FilterList } from "@material-ui/icons";
import { camelCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetcher } from "../../api/fetcher";
import { setContractorFilter } from "../../redux/actions/contractorFilterActions";
import { LoadingIconButton, useCustomSnackbar } from "../components";

export function ContractorFilterIconButton({ row }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackbar = useCustomSnackbar();
	const [filterLoading, setFilterLoading] = useState(false);
	const [filterNo, setFilterNo] = useState();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	function modifyFilterRequest(row) {
		setFilterLoading(true);
		setFilterNo(row?.No);

		fetcher(signal, "PUT", "/profile/contractor-filter", {
			AWAContractorFilter: row?.No,
		})
			.then((res) => {
				if (res?.ErrorMessage !== "") {
					// Bien faire attention qu'il existe une traduction lié au message d'erreur !
					snackbar.showError(`${t("translation:requestModifyFilterError")} ${t(camelCase(res?.ErrorMessage))}`);
				} else if (res?.ModifiedUserMenu?.AWAContractorFilter !== "") {
					dispatch(setContractorFilter(row));
					snackbar.showSuccess(t("translation:requestModifyFilterSuccess"));
				}
			})
			.catch(console.error)
			.finally(() => {
				setFilterLoading(false);
				setFilterNo(undefined);
			});
	}

	return (
		<LoadingIconButton
			icon={<FilterList />}
			title={t("translation:setContractorFilter")}
			loading={filterLoading && filterNo === row?.No}
			onClick={() => modifyFilterRequest(row)}
			size="small"
		/>
	);
}
