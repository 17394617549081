import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { camelCase } from "lodash";
import { desaturate, lighten } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetcher } from "../../api/fetcher";
import { setContractorFilter } from "../../redux/actions/contractorFilterActions";
import { setSelectedContractor } from "../../redux/actions/selectedContractorActions";
import variants from "../../theme/variants";
import { LoadingButton } from "./Buttons";
import { useCustomSnackbar } from "./Snackbar";

// #region Customisation des lignes de porté dans la séléction
const useStyles = makeStyles((theme) => ({
	contractorLine: {
		padding: theme.spacing(2),
		cursor: "pointer",
		// transition: "background-color .1s ease-out",
		"&:hover": {
			backgroundColor: lighten(0.1, desaturate(0.3, theme.palette.primary.main)),
			color: theme.palette.primary.contrastText,
		},
	},
	selectedContractorLine: {
		borderLeft: "2px solid black",
		fontWeight: "bold",
	},
}));

const ContractorLine = ({ selected, setSelected, elm }) => {
	const classes = useStyles();

	return (
		<Typography
			className={classes.contractorLine + " " + (selected?.No === elm?.No ? classes.selectedContractorLine : "")}
			onClick={() => setSelected(elm)}
		>
			{`${elm.No} - ${elm.FirstName} ${elm.LastName}`}
		</Typography>
	);
};
// #endregion Customisation des lignes de porté dans la séléction

export default function ContractorSearch({
	open,
	setOpen,
	/**
	 * @deprecated utiliser la valeur selectedContractor de redux si possible
	 */
	cb,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackbar = useCustomSnackbar();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [contractors, setContractors] = useState(); // Liste complète des portés
	const [selected, setSelected] = useState(); // Le porté sélectionné
	const [search, setSearch] = useState(""); // Le contenu du champ de recherche

	const [fetchLoading, setFetchLoading] = useState(false);
	const fetchContractors = () => {
		setFetchLoading(true);
		fetcher(signal, "GET", "/contractor")
			.then((data) => {
				if (!data) return;
				if (data === 204) setContractors(null);
				else if (data?.Contractors) setContractors(data.Contractors);
			})
			.catch((err) => {
				console.error(err);
				snackbar.showError(t("translation:errorDuringRequest"));
			})
			.finally(() => setFetchLoading(false));
	};

	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		// Tant que l'API ne renvoie rien, on relance la requête à chaque ouverture
		if (open && !contractors) {
			fetchContractors();
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reset de la recherche quand la liste des portés change
	useEffect(() => setSearch(""), [contractors]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);
	}

	function handleClose() {
		setOpen(false);
		setSelected(undefined); // On réinitialise le choix du porté à la fermeture de l'assistant
	}

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				{t("translation:contractorSearch")}
			</DialogTitle>

			<DialogContent dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("translation:dialogTypeToSearch")}
					autoFocus
				/>
			</DialogContent>

			<DialogContent style={{ height: 400 }} dividers>
				<Paper style={{ overflow: "auto" }}>
					{contractors === undefined && (
						<>
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
						</>
					)}
					{contractors === null && (
						<Grid container>
							<Grid item xs={12} sm={9}>
								<Alert severity="info" variant="filled">
									{t("translation:noContractorsFound")}
								</Alert>
							</Grid>
							<Grid container item sm justify="flex-end">
								<LoadingButton color="secondary" variant="contained" loading={fetchLoading} onClick={() => fetchContractors()} fullWidth>
									{t("translation:retry")}
								</LoadingButton>
							</Grid>
						</Grid>
					)}
					{Array.isArray(contractors) &&
						contractors
							.filter((contractor) => {
								// On ne filtre que ces propriétées
								const { No, FirstName, LastName } = contractor;

								return `${No} ${FirstName} ${LastName}`.toLowerCase().includes(search?.trim()?.toLowerCase());
							})
							.map((elm) => <ContractorLine key={elm.No} selected={selected} setSelected={setSelected} elm={elm} />)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setOpen(false)}>{t("translation:cancel")}</Button>
				<Button
					variant="contained"
					onClick={() => {
						// ! Deprécié
						// On appelle le callback avec le porté sélectionné
						typeof cb === "function" && cb(selected);

						// On met à jours la valeur dans redux
						dispatch(setSelectedContractor(selected));
						handleClose();
					}}
					disabled={selected === undefined}
					color="primary"
				>
					{t("translation:select")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export const ContractorSearchForFilter = ({ open, setOpen }) => {
	const snackbar = useCustomSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [contractors, setContractors] = useState(); // Liste complète des portés
	const [selected, setSelected] = useState(); // Le porté sélectionné
	const [search, setSearch] = useState(""); // Le contenu du champ de recherche
	const [isLoading, setIsLoading] = useState(false);
	const [searchFilteredContractors, setSearchFilteredContractors] = useState(); // Liste des portés qui correspondent au filtre

	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		if (open && !contractors) {
			fetcher(signal, "GET", "/contractor")
				.then((data) => {
					if (!data) return;
					if (data === 204) setContractors(null);
					else if (data?.Contractors) setContractors(data.Contractors);
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("translation:errorDuringRequest"));
				});
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reset de la recherche quand la liste des portés change
	// Permet aussi d'initialiser la liste quand le fetch à fini
	useEffect(() => {
		setSearchFilteredContractors(contractors);
		setSearch("");
	}, [contractors]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);

		// Filtrage de la liste selon la recherche
		setSearchFilteredContractors(
			contractors?.filter((contractor) => {
				// On ne filtre que ces propriétées
				const { No, FirstName, LastName } = contractor;

				return `${No} ${FirstName} ${LastName}`.toLowerCase().includes(value?.trim()?.toLowerCase());
			})
		);
	}

	const handleContractorSelect = () => {
		setIsLoading(true);
		fetcher(signal, "PUT", "/profile/contractor-filter", {
			AWAContractorFilter: selected?.No,
		})
			.then((res) => {
				if (res?.ErrorMessage !== "") {
					snackbar.showError(`${t("translation:requestModifyFilterError")} ${t(camelCase(res?.ErrorMessage))}`, "errorDuringRequest");
				} else if (res?.ModifiedUserMenu?.AWAContractorFilter !== "") {
					dispatch(setContractorFilter(selected));
					dispatch(setSelectedContractor(selected));
					snackbar.showSuccess(t("translation:requestModifyFilterSuccess"));
					setOpen(false);
				}
			})
			.catch(console.error)
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				{t("translation:contractorSearch")}
			</DialogTitle>

			{/* Barre de recherche */}
			<DialogContent style={{ overflowY: "visible" }} dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("translation:dialogTypeToSearch")}
					autoFocus
				/>
			</DialogContent>

			{/* Liste des portés */}
			<DialogContent dividers>
				<Paper>
					{searchFilteredContractors === undefined && (
						<>
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
						</>
					)}
					{searchFilteredContractors === null && (
						<>
							<Alert severity="info" variant="filled">
								{t("translation:noContractorsFound")}
							</Alert>
						</>
					)}
					{Array.isArray(searchFilteredContractors) &&
						searchFilteredContractors.map((elm) => <ContractorLine key={elm.No} selected={selected} setSelected={setSelected} elm={elm} />)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => setOpen(false)}>{t("translation:cancel")}</Button>
				<LoadingButton
					variant="contained"
					loading={isLoading}
					onClick={() => handleContractorSelect()}
					disabled={selected === undefined}
					color="primary"
				>
					{t("translation:select")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
