import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	InputAdornment,
	makeStyles,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { desaturate, lighten } from "polished";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from ".";
import { formatDateLong } from "../../api/date";
import { fetcher } from "../../api/fetcher";
import variants from "../../theme/variants";
import { useCustomSnackbar } from "./Snackbar";

// #region Customisation des lignes de porté dans la séléction
const useStyles = makeStyles((theme) => ({
	contractorLine: {
		padding: theme.spacing(2),
		cursor: "pointer",
		// transition: "background-color .1s ease-out",
		"&:hover": {
			backgroundColor: lighten(0.1, desaturate(0.3, theme.palette.primary.main)),
			color: theme.palette.primary.contrastText,
		},
	},
	selectedContractorLine: {
		borderLeft: "2px solid black",
		fontWeight: "bold",
	},
}));

const ContractorLine = ({ selected, setSelected, elm }) => {
	const { t, i18n } = useTranslation();
	const classes = useStyles();

	return (
		<Typography
			className={classes.contractorLine + " " + (selected?.No === elm?.No ? classes.selectedContractorLine : "")}
			onClick={() => setSelected(elm)}
		>
			{<b>{elm?.BillToName}</b>}
			{` - ${elm?.MissionName} (${t(formatDateLong(new Date(elm?.MissionBeginDate), i18n.language === "fr" ? "fr-FR" : "en-US"))} - ${t(
				formatDateLong(new Date(elm?.MissionEndDate), i18n.language === "fr" ? "fr-FR" : "en-US")
			)})`}
		</Typography>
	);
};
// #endregion Customisation des lignes de porté dans la séléction

export default function CommercialAgreementSearch({ open, setOpen, fieldValue }) {
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [commercialAgreement, setCommercialAgreement] = useState(); // Liste complète des CC
	const [selected, setSelected] = useState(); // Le CC sélectionné
	const [search, setSearch] = useState(""); // Le contenu du champs de recherche

	const [fetchLoading, setFetchLoading] = useState(false);
	useEffect(() => {
		// On ne fait la requête que l'orsque le client ouvre la pop-up, vive l'optimisation !
		if (open && !commercialAgreement) {
			setFetchLoading(true);
			fetcher(signal, "GET", "/commercial-agreement/initialized")
				.then((data) => {
					if (!data) return;
					if (data === 204) setCommercialAgreement(null);
					else if (data?.CA) setCommercialAgreement(data?.CA);
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("translation:errorDuringRequest"));
				})
				.finally(() => {
					setFetchLoading(false);
				});
		}
	}, [open, commercialAgreement]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reset de la recherche quand la liste des CC change
	useEffect(() => setSearch(""), [commercialAgreement]);

	function handleSearchChange(event) {
		// Remplissage du champ de recherche
		let value = event.target.value || "";
		setSearch(value);
	}

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
			<DialogTitle
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				{t("translation:commercialAgreementSearch")}
			</DialogTitle>

			<DialogContent dividers>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchOutlined />
							</InputAdornment>
						),
					}}
					fullWidth
					variant="outlined"
					onChange={(event) => handleSearchChange(event)}
					value={search}
					label={t("translation:dialogTypeToSearch")}
					autoFocus
				/>
			</DialogContent>

			<DialogContent style={{ height: 400 }} dividers>
				<Paper style={{ overflow: "auto" }}>
					{commercialAgreement === undefined && (
						<>
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
							<br />
							<Skeleton variant="rect" animation="wave" height={40} width="100%" />
						</>
					)}
					{commercialAgreement === null && (
						<Grid container>
							<Grid item xs={12} sm={9}>
								<Alert severity="info" variant="filled">
									{t("translation:noContractorsFound")}
								</Alert>
							</Grid>
							<Grid container item sm justify="flex-end">
								<LoadingButton
									color="secondary"
									variant="contained"
									loading={fetchLoading}
									onClick={() => {
										// fetchContractors()
									}}
									fullWidth
								>
									{t("translation:retry")}
								</LoadingButton>
							</Grid>
						</Grid>
					)}
					{Array.isArray(commercialAgreement) &&
						commercialAgreement
							.filter((commercialAgreement) => {
								// On ne filtre que ces propriétées
								const { No, CustomerReference, SellToCustomerName, MissionName } = commercialAgreement;

								return `${No} ${CustomerReference} ${SellToCustomerName}, ${MissionName}`.toLowerCase().includes(search?.trim()?.toLowerCase());
							})
							.map((elm) => <ContractorLine key={elm.No} selected={selected} setSelected={setSelected} elm={elm} />)}
				</Paper>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
				>
					{t("translation:cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						fieldValue(selected?.No);
						setOpen(false);
					}}
					disabled={selected === undefined}
					color="primary"
				>
					{t("translation:select")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
