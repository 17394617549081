// * https://codesandbox.io/s/mui-treeview-with-transitions-cmwi2o?file=/demo.js

import { Collapse, Typography } from "@material-ui/core";
import { blue, orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { AddBoxOutlined, IndeterminateCheckBoxOutlined, Remove } from "@material-ui/icons";
import { TreeItem, TreeView } from "@material-ui/lab";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

function TransitionComponent(props) {
	const style = useSpring({
		from: { opacity: 0, transform: "translate3d(20px,0,0)" },
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

TransitionComponent.propTypes = {
	//Show the component; triggers the enter or exit states
	in: PropTypes.bool,
};

const StyledTreeItem = withStyles(() => ({
	iconContainer: {
		"& .close": {
			opacity: 0.3,
		},
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed rgba(0,0,0,.4)`,
	},
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const ShowBrackets = ({ data, title }) => {
	const { t } = useTranslation();

	if (!data) return "";

	const brackets = Array.isArray(data) ? (data.length === 0 ? ": []" : " [...]") : " {...}";
	const length = Array.isArray(data) ? data.length : 0;

	return (
		<Typography>
			<b>
				{title} {brackets}
			</b>
			{length > 0 ? ` // ${length} ${t("translation:item")}` : ""}
		</Typography>
	);
};

const StyledValue = ({ data }) => {
	// Null
	if (data === null) return <span style={{ color: "grey" }}>null</span>; // eslint-disable-line
	// String
	else if (typeof data === "string") return <span style={{ color: orange[500] }}>&quot;{data}&quot;</span>;
	// Nombre, bool, class, ...
	else if (["number", "bigint", "boolean", "symbol", "function", "undefined"].indexOf(typeof data) > -1)
		return <span style={{ color: blue[700] }}>{data.toString()}</span>;
	// Tous les autres cas
	else return data;
};

let nodeId = 0;

const RecursiveTreeView = React.memo(({ data, title }) => {
	if (!data) return null;

	return (
		<StyledTreeItem nodeId={String(nodeId++)} label={ShowBrackets({ title, data })}>
			{Object.keys(data).map((k) => {
				if (typeof data[k] === "undefined") return null;

				// Si data est un tableau
				if (Array.isArray(data[k])) {
					return <RecursiveTreeView key={k} nodeId={String(nodeId++)} data={data[k]} title={k} />;
				}
				// Si data est un objet
				else if (typeof data[k] === "object" && data[k] !== null) {
					return <RecursiveTreeView key={k} nodeId={String(nodeId++)} data={data[k]} title={k} />;
				}
				// Si data est une donnée brute (null, bool, string, int, etc..)
				else {
					return (
						<StyledTreeItem
							key={k}
							nodeId={String(nodeId++)}
							label={
								<Typography>
									<b>{k}:</b> <StyledValue data={data[k]} />
								</Typography>
							}
						/>
					);
				}
			})}
		</StyledTreeItem>
	);
}, propsAreEqual);

function propsAreEqual(prev, next) {
	return isEqual(prev?.data, next?.data) && prev?.title === next?.title;
}

export default React.memo(function CustomizedTreeView({ data, title = "requestsDetails" }) {
	const { t } = useTranslation();
	nodeId = 0;

	return (
		<TreeView
			defaultExpanded={["0"]}
			defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
			defaultExpandIcon={<AddBoxOutlined />}
			defaultEndIcon={<Remove className="close" />}
		>
			<RecursiveTreeView data={data} title={t(`translation:${title}`)} />
		</TreeView>
	);
}, propsAreEqual);
