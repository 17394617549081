import * as types from "../constants";

export function setRequestHitory(value, url) {
	// Protection pour ne pas afficher le mot de passe en clair à l'utilisateur
	// Il s'agit d'UX uniquement, si on sais où chercher, on trouve le mot de passe à d'autres endroits (JWT, onglet "requête" de la devtool..)
	if (value?.AccPassword) value.AccPassword = "*****";

	return {
		type: types.SET_REQUEST_HISTORY,
		payload: { value, url },
	};
}
