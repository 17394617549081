/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../layouts/components/Async";

// #region Import des icônes
import {
	AccountBalance as AccountBalanceIcon,
	AccountBalanceWallet as AccountBalanceWalletIcon,
	Assignment as AssignmentIcon,
	BusinessRounded as BusinessIcon,
	Contacts as ContactIcon,
	CreditCard as CreditCardIcon,
	Dashboard as DashboardIcon,
	Description as DescriptionIcon,
	Euro as EuroIcon,
	EventNote as EventNoteIcon,
	PeopleAlt as PeopleAltIcon,
	Receipt as ReceiptIcon,
	Send as SendIcon,
	ViewList as ViewListIcon,
	Work as WorkIcon,
} from "@material-ui/icons";
import { User as UserIcon, Users as UsersIcon } from "react-feather";
// #endregion Icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const SignIn = Async(() => import("../pages/auth/SignIn"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
const Dashboard = Async(() => import("../pages/pages/Dashboard"));
// -- Définitions --
const authRoutes = {
	id: "auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/auth/login",
			name: "connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "resetPassword",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "pageNonTrouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "erreurInterne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "erreurRéseau",
			component: PageNetworkError,
		},
	],
};
const dashboardRoute = {
	id: "dashboard",
	path: "/",
	icon: <DashboardIcon />,
	component: Dashboard,
	containsHome: true,
};
// #endregion Composants de bases

// #region Profil
// -- Imports --
const Profile = Async(() => import("../pages/pages/Profile"));
// -- Définitions --
const profileRoutes = {
	id: "profile",
	path: "/profil",
	icon: <UserIcon />,
	component: Profile,
};
// #endregion Profil

// #region Informations société
// -- Imports --
const CompanyInformation = Async(() => import("../pages/pages/CompanyInformation"));
// -- Définitions --
const companyInformationRoutes = {
	id: "companyInformation",
	path: "/company-information",
	icon: <UserIcon />,
	component: CompanyInformation,
};
// #endregion Informations société

// #region Mon équipe
// -- Imports --
const Team = Async(() => import("../pages/pages/Team"));
const TeamContactDetails = Async(() => import("../pages/pages/TeamContactDetails"));
// -- Définitions --
const teamListRoute = {
	id: "team",
	path: "/team",
	icon: <UserIcon />,
	component: Team,
};
const teamContactDetailsRoute = {
	id: "teamContactDetails",
	path: "/team/:no",
	icon: <UserIcon />,
	component: TeamContactDetails,
};
// #endregion Mon équipe

// #region Portés
// -- Imports --
const ContractorsList = Async(() => import("../pages/pages/ContractorsList"));
const ContractorDetails = Async(() => import("../pages/pages/ContractorDetails"));
// const ContractorFilter = async(() => import("../pages/pages/ContractorFilter"));
// -- Définitions --
const contractorsListRoute = {
	id: "contractors",
	path: "/contractors",
	icon: <PeopleAltIcon />,
	component: ContractorsList,
	// children: [
	//   {
	//     path: "/contractors",
	//     name: "contractorsList",
	//     component: ContractorsList,
	//   },
	//   {
	//     path: "/contractors/filter",
	//     name: "contractorsFilters",
	//     component: ContractorFilter,
	//   },
	// ],
};
const contractorDetailsRoutes = {
	id: "contractorDetails",
	path: "/contractor/:No",
	icon: <UserIcon />,
	component: ContractorDetails,
};
// #endregion Portés

// #region Conventions de portage
// -- Imports --
const ConventionsList = Async(() => import("../pages/pages/ConventionsList"));
const ConventionDetails = Async(() => import("../pages/pages/ConventionDetails"));
// -- Définitions --
const conventionsListRoute = {
	id: "conventions",
	path: "/conventions",
	icon: <AssignmentIcon />,
	component: ConventionsList,
};
const conventionDetailsRoute = {
	id: "conventionDetails",
	path: "/convention/:No",
	icon: <UserIcon />,
	component: ConventionDetails,
};
// #endregion Conventions de portage

// #region Contrat de Travail
// -- Imports --
const WorkContractsList = Async(() => import("../pages/pages/WorkContractList"));
const WorkContractDetails = Async(() => import("../pages/pages/WorkContractDetails"));
// -- Définitions --
const workContractListRoute = {
	id: "workContracts",
	path: "/work-contracts",
	icon: <WorkIcon />,
	component: WorkContractsList,
};
const workContractDetailsRoute = {
	id: "workContractDetails",
	path: "/work-contract/:No",
	component: WorkContractDetails,
};
// #endregion Contrat de Travail

// #region Contrats Commerciaux
// -- Imports --
const CommercialAgreementList = Async(() => import("../pages/pages/CommercialAgreementList"));
const CommercialAgreementDetails = Async(() => import("../pages/pages/CommercialAgreementDetails"));
// -- Définitions --
const commercialAgreementsListRoutes = {
	id: "commercialAgreements",
	path: "/commercial-agreements",
	icon: <DescriptionIcon />,
	component: CommercialAgreementList,
	children: [
		{
			path: "/commercial-agreements",
			component: CommercialAgreementList,
			name: "caAll",
		},
		{
			path: "/commercial-agreements/status/initialized",
			component: CommercialAgreementList,
			name: "caInitialized",
		},
		{
			path: "/commercial-agreements/status/sent",
			component: CommercialAgreementList,
			name: "commercialAgreementCaStatusSent",
		},
		{
			path: "/commercial-agreements/status/active",
			component: CommercialAgreementList,
			name: "caActive",
		},
		{
			path: "/commercial-agreements/status/ended",
			component: CommercialAgreementList,
			name: "caEnded",
		},
	],
};
const commercialAgreementsDetailsRoute = {
	id: "commercialAgreementsDetails",
	path: "/commercial-agreements/:No",
	component: CommercialAgreementDetails,
};
// #endregion Contrats Commerciaux

// #region Clients
// -- Imports --
const CustomerList = Async(() => import("../pages/pages/CustomerList"));
const CustomerDetails = Async(() => import("../pages/pages/CustomerDetails"));
// -- Définitions --
const customerListRoute = {
	id: "customerList",
	path: "/customers",
	icon: <BusinessIcon />,
	component: CustomerList,
};
const customerDetailsRoute = {
	id: "customerDetails",
	path: "/customer/:No",
	icon: <BusinessIcon />,
	component: CustomerDetails,
};
// #endregion Clients

// #region Factures
// -- Imports --
const SalesInvoiceList = Async(() => import("../pages/pages/SalesInvoiceList"));
const SalesInvoiceDetails = Async(() => import("../pages/pages/SalesInvoiceDetails"));
// -- Définitions --
const salesInvoiceListRoute = {
	id: "salesInvoices",
	path: "/sales-invoices",
	icon: <ReceiptIcon />,
	component: SalesInvoiceList,
};
const salesInvoiceDetails = {
	id: "salesInvoices",
	path: "/sales-invoice/:No",
	icon: <ReceiptIcon />,
	component: SalesInvoiceDetails,
};
// #endregion Factures

// #region Avoirs
// -- Imports --
// const SalesCreditList = Async(() => import("../pages/pages/SalesCreditList"));
const SalesCrMemoList = Async(() => import("../pages/pages/SalesCrMemoList"));
const SalesCrMemoDetails = Async(() => import("../pages/pages/SalesCrMemoDetails"));
// const SalesCreditDetails = Async(() => import("../pages/pages/SalesCreditDetails"));
// -- Définitions --
const salesCrMemoListRoute = {
	id: "salesCrMemos",
	icon: <ReceiptIcon />,
	path: "/sales-cr-memos",
	component: SalesCrMemoList,
	// children: [
	// 	{
	// 		path: "/sales-credit",
	// 		component: SalesCreditList,
	// 		name: "salesCredit",
	// 	},
	// 	{
	// 		path: "/sales-cr-memos",
	// 		component: SalesCrMemoList,
	// 		name: "salesCrMemos",
	// 	},
	// ],
};
const salesCrMemoDetailsRoute = {
	id: "salesCrMemos",
	path: "/sales-cr-memo/:No",
	icon: <ReceiptIcon />,
	component: SalesCrMemoDetails,
};
// const salesCreditDetailsRoute = {
// 	id: "salesCredit",
// 	path: "/sales-credit/:No",
// 	icon: <ReceiptIcon />,
// 	component: SalesCreditDetails,
// };
// #endregion Avoirs

// #region Contacts
// -- Imports --
const ContactList = Async(() => import("../pages/pages/ContactsList"));
const ContactDetails = Async(() => import("../pages/pages/ContactDetails"));
// -- Définitions --
const contactListRoute = {
	id: "contact",
	path: "/contacts",
	icon: <ContactIcon />,
	component: ContactList,
};
const contactDetailsRoute = {
	id: "contatDetails",
	path: "/contact/:No",
	component: ContactDetails,
};
// #endregion Contacts

// #region Demande de facturation
// -- Imports --
const InvoiceRequestList = Async(() => import("../pages/pages/InvoiceRequestList"));
const InvoiceRequestDetails = Async(() => import("../pages/pages/InvoiceRequestDetails"));
// -- Définitions --
const invoiceRequestListRoute = {
	id: "invoiceRequests",
	icon: <SendIcon />,
	children: [
		{
			path: "/invoice-requests/status/pending", // En cours
			component: InvoiceRequestList,
			name: "irPending",
		},
		{
			path: "/invoice-requests/status/to-validate", // À valider
			component: InvoiceRequestList,
			name: "irToValidate",
		},

		// {
		// 	path: "/invoice-requests/status/invoiced", // Facturé
		// 	component: InvoiceRequestList,
		// 	name: "irInvoiced",
		// },
	],
};
const invoiceRequestDetailsRoute = {
	id: "invoiceRequests",
	path: "/invoice-request/:No",
	icon: <SendIcon />,
	component: InvoiceRequestDetails,
};
// #endregion Demande de facturation

// #region Rapports d'activité
// -- Imports --
const ActivityReportsList = Async(() => import("../pages/pages/ActivityReportsList"));
const ActivityReportDetails = Async(() => import("../pages/pages/ActivityReportDetails"));
// -- Définitions --
const activityReportsListRoute = {
	id: "activityReports",
	path: "/activity-reports",
	icon: <EventNoteIcon />,
	component: ActivityReportsList,
};
const activityReportDetailsRoute = {
	id: "activityReport",
	path: "/activity-report/:ActivityNo",
	icon: <EventNoteIcon />,
	component: ActivityReportDetails,
};
// #endregion Frais

// #region Frais
// -- Imports --
// const ChargesList = Async(() => import("../pages/pages/ChargesList"));
const ChargesList = Async(() => import("../pages/pages/ChargesList"));
const ChargesDetails = Async(() => import("../pages/pages/ChargesDetails"));
// -- Définitions --
const chargesListRoute = {
	id: "charges",
	path: "/charges",
	icon: <EuroIcon />,
	component: ChargesList,
	children: [
		{
			path: "/charges/status/initialized", // En cours de saisie
			component: ChargesList,
			name: "inProgress",
		},
		{
			path: "/charges/status/to-validate", // À valider
			component: ChargesList,
			name: "toValidate",
		},
		{
			path: "/charges/status/history", // Enregistré
			component: ChargesList,
			name: "chargesHistory",
		},
	],
};
const chargesToValidateDetailsRoute = {
	id: "chargesToValidate",
	path: "/charge-to-validate/:ChargesNo",
	icon: <EuroIcon />,
	component: ChargesDetails,
};

const pendingChargeDetailsRoute = {
	id: "chargesToValidate",
	path: "/pending-charge/:ChargesNo",
	icon: <EuroIcon />,
	component: ChargesDetails,
	children: null,
};

const chargeHistoryDetailsRoute = {
	id: "chargesHistoryDetails",
	path: "/charge-history/:ChargesNo",
	icon: <EuroIcon />,
	component: ChargesDetails,
};
// #endregion Frais

// #region Compte D'activité
// -- Imports --
const ActivityAccountList = Async(() => import("../pages/pages/ActivityAccountList"));
const ActivityAccountListFiltered = Async(() => import("../pages/pages/ActivityAccountListFiltered"));
// -- Définitions --
const activityAccountListRoute = {
	id: "activityAccount",
	path: "/activity-account",
	icon: <AccountBalanceIcon />,
	component: ActivityAccountList,
};
const activityAccountListFilteredRoute = {
	id: "activityAccount",
	path: "/activity-account/:ContractorNo",
	icon: <AccountBalanceIcon />,
	component: ActivityAccountListFiltered,
};
// #endregion Compte D'activité

// #region Payes
// -- Imports --
const WagesList = Async(() => import("../pages/pages/WagesList"));
const WageDetails = Async(() => import("../pages/pages/WageDetails"));
// -- Définitions --
const wageListRoute = {
	id: "wage",
	path: "/wages",
	icon: <AccountBalanceIcon />,
	component: WagesList,
};
const wageDetailsRoute = {
	id: "wage",
	path: "/wage/:No",
	component: WageDetails,
};
// #endregion

// #region Import de paye
// -- Imports --
const ImportedWageList = Async(() => import("../pages/pages/ImportedWageList"));
// -- Définitions --
const importedWageListRoute = {
	id: "importedWage",
	path: "/imported-wage",
	icon: <AccountBalanceIcon />,
	component: ImportedWageList,
};
// #endregion Import de paye

// #region Préparation de paies
// -- Imports --
const WagePreparationList = Async(() => import("../pages/pages/WagePreparationList"));
const WagePreparationDetails = Async(() => import("../pages/pages/WagePreparationDetails"));
// -- Définitions --
const wagePreparationListRoute = {
	id: "wagePreparation",
	icon: <AccountBalanceWalletIcon />,
	path: "/wage-preparation",
	component: WagePreparationList,
};
const wagePreparationDetailsRoute = {
	id: "wagePreparationDetails",
	path: "/wage-preparation/:No",
	component: WagePreparationDetails,
};
// #endregion Préparation de paies

// #region Règlements
// -- Imports --
const PaymentsList = Async(() => import("../pages/pages/PaymentsList"));
// -- Définitions --
const paymentsListRoute = {
	id: "payments",
	path: "/payments",
	icon: <CreditCardIcon />,
	component: PaymentsList,
};
// #endregion Règlements

// #region Corrections compte d'activité
// -- Imports --
const EntriesCorrectionsList = Async(() => import("../pages/pages/EntriesCorrectionsList"));
const entriesCorrectionsListRoute = {
	id: "entriesCorrections",
	path: "/entries-correction",
	icon: <ViewListIcon />,
	component: EntriesCorrectionsList,
};
// #endregion Corrections compte d'activité
// #endregion Imports et définitions des routes du projet

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [
	dashboardRoute,
	profileRoutes,
	companyInformationRoutes,
	// -- Client --
	customerListRoute,
	customerDetailsRoute,
	// -- Porté --
	contractorsListRoute,
	contractorDetailsRoutes,
	// -- Convention de portage --
	conventionsListRoute,
	conventionDetailsRoute,
	// -- Contrat de Travail --
	workContractListRoute,
	workContractDetailsRoute,
	// -- Contrat Commercial --
	commercialAgreementsListRoutes,
	commercialAgreementsDetailsRoute,
	// -- Demande de facturation --
	invoiceRequestListRoute,
	invoiceRequestDetailsRoute,
	// -- Rapports d'activité --
	activityReportsListRoute,
	activityReportDetailsRoute,
	// -- Frais --
	chargesListRoute,
	pendingChargeDetailsRoute, // Frais en cours de saisie
	chargesToValidateDetailsRoute, // Frais à valider
	chargeHistoryDetailsRoute, // Frais enregistrés
	// -- Contacts --
	contactListRoute,
	contactDetailsRoute,
	// -- Factures --
	salesInvoiceListRoute,
	salesInvoiceDetails,
	// -- Avoirs --
	salesCrMemoListRoute,
	salesCrMemoDetailsRoute,
	// salesCreditDetailsRoute,
	// -- Équipe --
	teamListRoute,
	teamContactDetailsRoute,
	// -- Compte d'activité --
	activityAccountListRoute,
	activityAccountListFilteredRoute,
	// -- Corrections compte d'activité --
	entriesCorrectionsListRoute,
	// -- Règlements --
	paymentsListRoute,
	// -- Payes --
	wageListRoute,
	wageDetailsRoute,
	wagePreparationListRoute,
	wagePreparationDetailsRoute,
	importedWageListRoute,
];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [
	dashboardRoute, // TDB
	"contractor",
	"------",
	contractorsListRoute, // Portés
	conventionsListRoute, // Convention de portage
	customerListRoute, // Clients
	contactListRoute, // Contacts
	commercialAgreementsListRoutes, // Contrats Commerciaux
	workContractListRoute, // Contrats de Travail
	chargesListRoute, // Frais
	activityReportsListRoute, // Rapports d'activité
	invoiceRequestListRoute, // Demandes de facturations
	importedWageListRoute, // Import de la paye
	activityAccountListRoute, // Compte d'activité
	entriesCorrectionsListRoute, // Correction compte d'activité
	"accounting",
	"------",
	salesInvoiceListRoute, // Factures enregistrés
	salesCrMemoListRoute, // Avoirs enregistrés
	paymentsListRoute, // Règlements
	// "caManagement",
	// "------",
	// wagePreparationListRoute, // Préparation de la paye
	// wageListRoute, // Payes
];
// #endregion Export des routes
