import { Chip } from "@material-ui/core";
import { amber, blue, green, grey, orange, purple, red } from "@material-ui/core/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { pascalCase } from "../../api/strings";

export const StatusContractorChip = (props) => {
	const { t } = useTranslation();
	const { status, ...rest } = props;

	let color = { background: "", color: "#FFF" };

	switch (status) {
		case "Available":
			color.background = green[500];
			break;
		case "Active":
			color.background = red[500];
			break;
		case "End_of_Contract":
			color.background = purple[500];
			break;
		case "Terminated":
			color.background = blue[500];
			break;
		case "Don_x0027_t_Calculate":
			color.background = amber[500];
			break;
		default:
	}

	return <Chip {...rest} style={color} label={t("translation:contractorStatus" + pascalCase(status), status)} variant="outlined" />;
};

export function StatusChip(props) {
	const { t } = useTranslation();
	const { status, ...rest } = props;
	let color = { background: "", color: "#FFF" };

	if (status === undefined || status === "") return null;

	switch (status) {
		case 0:
		case "Draft_copy":
			color.background = grey[700];
			break;
		case 1:
		case "Open":
			color.background = blue[500];
			break;
		case 2:
		case "Pending":
			color.background = green[500];
			break;
		case 3:
		case "In_Progress":
			color.background = orange[500];
			break;
		case 4:
		case "Closed":
			color.color = grey[600];
			break;
		case 5:
		case "Improvement":
			color.background = red[500];
			break;
		default:
			color.background = " white";
			console.warn(`Unhandled status "${status}" in StatusChip.js!`);
	}

	return (
		<Chip
			{...rest}
			style={color}
			label={(() => {
				switch (status) {
					case 0:
					case "Draft_copy":
						return t("translation:ticket-draft_copy");
					case 1:
					case "Open":
						return t("translation:ticket-open");
					case 2:
					case "Pending":
						return t("translation:ticket-pending");
					case 3:
					case "In_Progress":
						return t("translation:ticket-in_progress");
					case 4:
					case "Closed":
						return t("translation:ticket-closed");
					case 5:
					case "Improvement":
						return t("translation:ticket-improvement");
					default:
						console.warn(`Unhandled status "${status}" in StatusChip.js!`);
				}
			})()}
			variant={status === "Closed" ? "outlined" : "default"}
		></Chip>
	);
}
