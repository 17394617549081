import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Link, Tab, Tabs, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useRequestHistory from "../../hooks/useRequestHistory";
import { StyledTreeView, TabPanel, Tooltip } from "../components";

export default function SupportDialog() {
	const [open, setOpen] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);

	const { t } = useTranslation();

	const handleChange = (_event, newValue) => {
		setTabIndex(newValue);
	};

	return (
		<>
			<Tooltip title={t("translation:support")}>
				<IconButton onClick={() => setOpen(true)} style={{ color: "inherit" }}>
					<Help />
				</IconButton>
			</Tooltip>

			<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
				<DialogContent dividers style={{ paddingTop: "4em" }}>
					<AppBar position="absolute">
						<Tabs value={tabIndex} variant="fullWidth" onChange={handleChange}>
							<Tab label={t("translation:documentation")} value={0} id="simple-tab-0" />
							<Tab label={t("translation:supportRequest")} value={1} id="simple-tab-1" />
							<Tab label={t("translation:support")} value={2} id="simple-tab-2" />
						</Tabs>
					</AppBar>
					<TabPanel value={tabIndex} index={0}>
						<DocumentationTab />
					</TabPanel>
					<TabPanel value={tabIndex} index={1}>
						<SupportRequestTab />
					</TabPanel>
					<TabPanel value={tabIndex} index={2}>
						{/* #region Spé [x] AWA | [ ] CWA - Arborescence - Details des requêtes */}
						<RequestTreeView />
						{/* #endregion Spé [x] AWA | [ ] CWA - Arborescence - Details des requêtes */}

						{/* #region Spé [ ] AWA | [x] CWA - Affichage du lien vers le club */}
						{/* <AssistRequestTab /> */}
						{/* #endregion Spé [ ] AWA | [x] CWA - Affichage du lien vers le club */}
					</TabPanel>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => setOpen(false)}>{t("translation:close")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function DocumentationTab() {
	const { t } = useTranslation();

	return <Typography>{t("translation:noDocumentationFound")}</Typography>;
}

function SupportRequestTab() {
	return (
		<Grid container>
			<Grid item>
				<Typography>
					<Trans
						i18nKey="translation:requestSupportExplanation"
						components={[<Link key="0" href="https://zen.et9.fr" style={{ color: "#62b852" }} target="_blank" />]}
					></Trans>
				</Typography>
			</Grid>
		</Grid>
	);
}

// #region Spé [ ] AWA | [x] CWA - Club utilisateur sur CWA
// function AssistRequestTab() {
// 	return (
// 		<Grid container>
// 			<Grid item>
// 				<Typography>
// 					<Trans
// 						i18nKey="translation:requestAssistanceExplanation"
// 						components={[<Link key="0" href="https://club.dynamics-portage-salarial.fr/" style={{ color: "#62b852" }} target="_blank" />]}
// 					></Trans>
// 				</Typography>
// 			</Grid>
// 		</Grid>
// 	);
// }
// #endregion Spé [ ] AWA | [x] CWA - Club utilisateur sur CWA

// #region Spé [x] AWA | [ ] CWA - Détails des requêtes sur AWA
function RequestTreeView() {
	const requestHistory = useRequestHistory();

	return (
		<Grid container justify="space-between" spacing={2}>
			<Grid item xs={12}>
				<StyledTreeView data={requestHistory} />
			</Grid>
		</Grid>
	);
}
// #endregion Spé [x] AWA | [ ] CWA - Détails des requêtes sur AWA
