import * as types from "../constants";

const INITIAL_STATE = { selectedContractor: undefined };

export default function reducer(state = INITIAL_STATE, actions) {
	switch (actions.type) {
		case types.SET_SELECTED_CONTRACTOR:
			return {
				...state,
				selectedContractor: actions.payload,
			};

		case types.CLEAR_SELECTED_CONTRACTOR:
			return { selectedContractor: null };

		default:
			return state;
	}
}
