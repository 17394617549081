import * as types from "../constants";

const INITIAL_STATE = { response: {} };

export default function reducer(state = INITIAL_STATE, actions) {
	switch (actions.type) {
		case types.SET_REQUEST_HISTORY:
			return {
				...state,
				response: { ...state.response, [actions.payload.url]: actions.payload.value },
			};

		default:
			return state;
	}
}
