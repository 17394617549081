// Choix du thème s'il y en a plusieurs de définis
export const SET_THEME = "SET_THEME";

// Filtre porté
export const SET_CONTRACTOR_FILTER = "SET_CONTRACTOR_FILTER";
export const CLEAR_CONTRACTOR_FILTER = "CLEAR_CONTRACTOR_FILTER";

// Historique des requêtes visible dans l'assistant d'aide
export const SET_REQUEST_HISTORY = "SET_REQUEST_HISTORY";

// Copie temporaire du filtre porté, permettant de choisir un porté via un dialog de recherche
export const SET_SELECTED_CONTRACTOR = "SET_SELECTED_CONTRACTOR";
export const CLEAR_SELECTED_CONTRACTOR = "CLEAR_SELECTED_CONTRACTOR";
