import { AppBar, Button, ButtonGroup, Grid, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { AccountCircle, ChevronLeft, Delete as DeleteIcon, FilterList, Menu as MenuIcon, WarningOutlined } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/styles";
import { spacing } from "@material-ui/system";
import clsx from "clsx";
import { camelCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import { showTranslations } from "translation-check";
import { parseJwt, removeToken } from "../../api";
import useFetch, { fetcher } from "../../api/fetcher";
import { getDevMode } from "../../api/localStorage";
import useContractorFilter from "../../hooks/useContractorFilter";
import { ContractorSearchForFilter, IconButton, LoadingIconButton, useCustomSnackbar } from "../../pages/components";
import ToggleSaveOnBlur from "../../pages/components/ToggleSaveOnBlur";
import SupportDialog from "../../pages/dialogs/SupportDialog";
import { clearContractorFilter, setContractorFilter } from "../../redux/actions/contractorFilterActions";
import { setSelectedContractor } from "../../redux/actions/selectedContractorActions";

// #region Customisation de certains éléments
const Flag = styled.img`
	border-radius: 50%;
	width: 22px;
	height: 22px;
`;

const AccountIcon = styled(AccountCircle)`
	height: 28px;
	width: 28px;
`;

const WarningOutlinedIcon = styled(WarningOutlined)(spacing);

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${theme.sidebar.width}px)`,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	drawer: {
		width: theme.sidebar.width,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	logo: {
		maxWidth: "250px",
		maxHeight: "55px",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));
// #endregion Customisation de certains éléments

const LanguageMenu = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();

	const [anchorMenu, setAnchorMenu] = useState(null);

	const toggleMenu = (event) => {
		if (event.ctrlKey && getDevMode()) return showTranslations(i18n);

		setAnchorMenu(event.currentTarget);
	};

	const setLanguage = (lng) => {
		// On évite d'appeler la fonction si ce n'est pas nécessaire
		if (i18n.language !== lng) {
			i18n.changeLanguage(lng);
		}
		// Quoi qu'il arrive, on ferme le menu
		closeMenu();
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	return (
		<MenuItem ref={ref}>
			<Button
				color="inherit"
				onClick={toggleMenu}
				endIcon={<Flag src={t("translation:lngFlag")} alt={t("translation:lngName")} />}
				style={{ padding: 0, fontSize: "16px", fontWeight: 400 }} // Copie du style des autre boutons du menu
			>
				{t("translation:chooseLanguage")}
			</Button>
			<Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
				<MenuItem onClick={() => setLanguage("en")}>{t("translation:english")}</MenuItem>
				<MenuItem onClick={() => setLanguage("fr")}>{t("translation:french")}</MenuItem>
			</Menu>
		</MenuItem>
	);
});

const UserMenu = withRouter(() => {
	const { t } = useTranslation();
	const history = useHistory();

	// #region Gestion ouverture / fermeture du menu
	const [anchorMenu, setAnchorMenu] = useState(null);

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};
	// #endregion Gestion ouverture / fermeture du menu

	const logout = () => {
		removeToken();
		history.push("/auth/login");
	};

	// Récupération du nom de l'utilisateur (si disponible)
	const { AccFirstName, AccLogin } = parseJwt();
	var username = AccFirstName || AccLogin || "";

	return (
		<>
			<SupportDialog />

			<Button color="inherit" onClick={toggleMenu} startIcon={<AccountIcon />}>
				{username}
			</Button>
			<Menu open={Boolean(anchorMenu)} anchorEl={anchorMenu} onClose={closeMenu}>
				<LanguageMenu />
				<MenuItem component={RouterLink} to="/company-information">
					{t("translation:companyInformation")}
				</MenuItem>
				<MenuItem>
					<ToggleSaveOnBlur />
				</MenuItem>
				{/* <MenuItem component="a" target="_blank" href="/profil">
					{t("translation:profile")}
				</MenuItem> */}
				{/* <MenuItem component={RouterLink} onClick={() => history.push("/team")}>
					{t("translation:team")}
				</MenuItem> */}
				<MenuItem onClick={logout} style={{ backgroundColor: red[700], color: "#EEE" }}>
					{t("translation:disconnect")}
				</MenuItem>
			</Menu>
		</>
	);
});

export default function Header({ open, setOpen }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const snackbar = useCustomSnackbar();
	const contractorFilter = useContractorFilter();

	const [openContractorSearch, setOpenContractorSearch] = useState(false);

	// #region Gestion de l'affichage du filtre porté
	const { data } = useFetch("GET", "/profile/filter");

	useEffect(() => {
		if (data?.ContractorFilter) {
			dispatch(setContractorFilter(data.ContractorFilter));
			dispatch(setSelectedContractor(data.ContractorFilter));
		} else if (data?.ContractorFilter === null || data instanceof Error) {
			dispatch(setContractorFilter(null));
			dispatch(setSelectedContractor(null));
		}
	}, [data, dispatch]);
	// #endregion Gestion de l'affichage du filtre porté

	// #region Suppression du filtre porté
	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const [loading, setLoading] = useState(false);
	function deleteContractorFilter() {
		if (loading) return;

		setLoading(true);
		fetcher(signal, "PUT", "/profile/contractor-filter", {
			AWAContractorFilter: "",
		})
			.then((res) => {
				if (res?.ErrorMessage !== "") {
					// Bien faire attention qu'il existe une traduction lié au message d'erreur !
					snackbar.showError(`${t("translation:requestModifyFilterError")} ${t(camelCase(res?.ErrorMessage))}`);
				} else if (res?.ModifiedUserMenu?.AWAContractorFilter === "") {
					dispatch(clearContractorFilter());
					snackbar.showSuccess(t("translation:requestModifyFilterSuccess"));
				}
			})
			.catch(console.error)
			.finally(() => setLoading(false));
	}
	// #endregion Suppression du filtre porté

	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={() => setOpen(!open)}
					edge="start"
					icon={open ? <ChevronLeft /> : <MenuIcon />}
					title={t(`translation:${open ? "close" : "open"}Drawer`)}
				/>

				<Grid container alignItems="center">
					{/* Affichage du filtre porté */}
					<Grid item xs container alignItems="center">
						<ContractorSearchForFilter open={openContractorSearch} setOpen={setOpenContractorSearch} />
						{(() => {
							if (contractorFilter === undefined) {
								return (
									<Grid item>
										<Skeleton animation="wave" width="200px" height="30px" />
									</Grid>
								);
							} else if (contractorFilter === null) {
								return (
									<>
										<ButtonGroup variant="text" style={{ color: "inherit" }}>
											<IconButton
												style={{ color: "inherit", border: "none" }}
												onClick={() => setOpenContractorSearch(true)}
												icon={<WarningOutlinedIcon />}
												title={t("translation:noContractorFilter")}
											/>
											<Button style={{ color: "inherit", border: "none" }} onClick={() => setOpenContractorSearch(true)}>
												<Typography variant="h6">{t("translation:noContractorFilter")}</Typography>
											</Button>
										</ButtonGroup>
									</>
								);
							} else {
								return (
									<ButtonGroup variant="text" style={{ color: "inherit" }}>
										<IconButton
											style={{ color: "inherit", border: "none" }}
											onClick={() => setOpenContractorSearch(true)}
											icon={<FilterList />}
											title={t("translation:contractorFilterIcon")}
										/>
										<Button style={{ color: "inherit", border: "none" }} onClick={() => setOpenContractorSearch(true)}>
											<Typography variant="h6">{contractorFilter.FirstName + " " + contractorFilter.LastName}</Typography>
										</Button>
										<LoadingIconButton
											style={{ color: "inherit", border: "none", borderRadius: "0 50% 50% 0" }}
											title={t("translation:deleteContractorFilter")}
											loading={loading}
											onClick={deleteContractorFilter}
											icon={<DeleteIcon />}
										/>
									</ButtonGroup>
								);
							}
						})()}
					</Grid>

					{/* Menu de profil */}
					<Grid item>
						<UserMenu />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}
