import * as types from "../constants";

export function setSelectedContractor(value) {
	return {
		type: types.SET_SELECTED_CONTRACTOR,
		payload: value,
	};
}

export function clearSelectedContractor() {
	return { type: types.CLEAR_SELECTED_CONTRACTOR };
}
