import { combineReducers } from "redux";

import themeReducer from "./themeReducers";
import contractorFilterReducer from "./contractorFilterReducers";
import requestHistoryReducer from "./requestHistoryReducer";
import selectedContractorReducer from "./selectedContractorReducers";

export default combineReducers({
	themeReducer,
	contractorFilterReducer,
	requestHistoryReducer,
	selectedContractorReducer,
});
